import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import { PanelHeader, Spinner } from '../../../../components';
import Select from 'react-select';
import Switch from 'react-bootstrap-switch';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { loadOneDevis, one } from '../../../../helpers/actions/projects';
import { moneyFormatter } from '../../../../helpers/formatters';
import GroupCard from './GroupCard';

class DevisDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      devis: null,
      project: null,
      provider: null,
      pieces: [],
      piece: null,
      fromCustom: false,
      fromCatalog: false,
      fromPackage: false,
      changeStatus: false,
      alert: null
    };
  }

  load() {
    this.setState({ loading: true });

    let { id, devisId } = this.props.match.params,
      { dispatch } = this.props;

    let promises = [
      dispatch(
        one(id, {
          include: ['none'],
          fields: ['type', 'name']
        })
      )
    ];

    if (devisId) {
      promises.push(
        dispatch(
          loadOneDevis(id, devisId, {
            fields: [
              'title',
              'price',
              'base',
              // 'project',
              'groups',
              'parent',
              'status',
              'user',
              'created_at',
              'updated_at'
            ]
          })
        )
      );
    }

    Promise.all(promises)
      .then(([project, devis]) => {
        let pieces = [];
        if (devis && devis.groups && devis.groups.length) {
          pieces = devis.groups.map(group => {
            return group.name;
          });
        }

        this.setState(prevState => {
          return {
            ...prevState,
            pieces,
            project,
            devis,
            provider: project.provider,
            loading: false
          };
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  render() {
    let { loading, project, devis, provider, pieces, piece } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}

          <Card>
            {devis ? (
              <CardBody>
                <CardTitle tag={'h6'}>{devis.title}</CardTitle>
                <Row>
                  <Col xs={12}>
                    <Switch
                      onText={<i className="now-ui-icons ui-1_check" />}
                      offText={
                        <i className="now-ui-icons ui-1_simple-remove" />
                      }
                      value={devis.base}
                      disabled={true}
                      readonly={true}
                      onColor={'success'}
                      offColor={'danger'}
                    />{' '}
                    <Trans>Devis Base</Trans>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup className={'has-label'}>
                      <Label>
                        <Trans>Piece</Trans>
                      </Label>
                      <Select
                        value={piece}
                        options={pieces.map(t => ({
                          label: t,
                          value: t
                        }))}
                        onChange={event =>
                          this.setState({ piece: event ? event.value : null })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={8}>
                    <Link
                      key={'button-print'}
                      to={`/my-projects/${project._id}/devis/${devis._id}/print`}
                      className="btn btn-info float-right"
                    >
                      <i className="fa fa-print" /> <Trans>Print</Trans>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            ) : null}
          </Card>

          {devis ? (
            <Row>
              <Col xs={6} md={6}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>Pro HT</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(get(devis, 'price.proHt', 0))}
                  </CardTitle>
                </Card>
              </Col>
              <Col xs={6} md={6}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>Pro HT + Mat</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(
                      get(devis, 'price.proHt', 0) +
                        get(devis, 'price.materials', 0)
                    )}
                  </CardTitle>
                </Card>
              </Col>
            </Row>
          ) : null}

          {!devis
            ? null
            : devis.groups.map((group, key) => {
                return !piece || (piece && piece === group.name) ? (
                  <GroupCard
                    key={key}
                    devis={devis}
                    group={group}
                    groupIndex={key}
                    totalGroups={devis.groups.length}
                    provider={provider}
                  />
                ) : null;
              })}

          <Card>
            <CardBody>
              {devis ? (
                <Row>
                  <Col xs={12}>
                    <strong>
                      <Trans>Comments</Trans>
                    </strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: get(devis, 'subtitle', '')
                      }}
                    />
                  </Col>
                </Row>
              ) : null}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(DevisDetails));
